
export default {
  name: 'IndexPage',
  async asyncData ({ $content, i18n, req }) {
    const locale = i18n.locale
    const page = await $content(locale + '/home').fetch()

    return {
      page
    }
  },
  head () {
    return {
      title: this.page.title,
      meta: [
        {
          hid: 'description',
          content: this.page.description
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$host + this.$route.path
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'article'
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.page.title
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.page.description
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.$host + this.page.image
        }
      ]
    }
  }
}
